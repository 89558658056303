import * as React from "react";
import { Link } from "gatsby";
import { withLayout } from "../components/Layout";

const NotFoundPage = () => (
  <div
    style={{
      minHeight: "calc(100vh - 39px)",
      background: "#5a8926",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fbed21"
    }}
  >
    <div>
      <h1 style={{ margin: 0 }}>You are here!</h1>
      <h2>But there isn't anything else! #404</h2>
      <Link to={"/"} style={{ textDecoration: "none" }}>
        <p style={{color: "#fff"}}>Back to the site!</p>
      </Link>
    </div>
  </div>
);

export default withLayout(NotFoundPage);
